import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/14-fabrics.jpeg";
import TopBackgroundMobile from "../images/headersMobile/for-fabric-suppliers-mobile.jpg";
import Slide1 from "../images/slides/fabric-suppliers/fabric-profile-catalogue-fabric-view.png";
import Slide2 from "../images/slides/fabric-suppliers/fabric-profile-about-fabric-view.png";
import Slide3 from "../images/slides/fabric-suppliers/catalogue-search-3d-filter-buyer-view.png";
import Slide4 from "../images/slides/fabric-suppliers/3d-fabric-light-box-buyer-view.png";
import Slide5 from "../images/slides/fabric-suppliers/request-for-quotations-fabric-view.png";
import Slide6 from "../images/slides/fabric-suppliers/business-opportunities-fabric-view.png";
import Slide7 from "../images/slides/fabric-suppliers/searching-for-buyer-fabric-view.png";
import Slide8 from "../images/slides/fabric-suppliers/apparel-mf-message-fabric-view.png";
import Slide9 from "../images/slides/fabric-suppliers/apparel-website.png";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("fabric-suppliers.topPretitle"),
        colorClass: "colorLight",
        extraClass: "font24 fontWeight400 ",
      },
      subtitle: {
        text: t("fabric-suppliers.topTitle"),
        colorClass: "colorLight",
        extraClass: "font50 ",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("fabric-suppliers.imgAlt"),
      },
      cta: {
        text: t("meta.demoRequestText"),
        url: t("meta.demoDeskLink"),
        type: "infoColor",
        extraClass: "bigWidth",
      },
    },
    topInfoMobile: {
      title: {
        text: t("fabric-suppliers.topPretitle"),
        colorClass: "colorLight",
        extraClass: "font24 fontWeight400 shadowEffect",
      },
      subtitle: {
        text: t("fabric-suppliers.topTitle"),
        colorClass: "colorLight",
        extraClass: "font50 shadowEffect",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackgroundMobile,
        alt: t("fabric-suppliers.imgAlt"),
      },
    },
    pageContent: [
      {
        info: {
          title: {
            text: t("fabric-suppliers.buildNetwork"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("fabric-suppliers.standOut"),
              t("fabric-suppliers.identifyLeads"),
              t("fabric-suppliers.exhibitLatest"),
              t("fabric-suppliers.buildTrust"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide1, Slide2],
        },
        isTextFirst: true,
      },
      {
        info: {
          title: {
            text: t("fabric-suppliers.minimizeCosts"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("fabric-suppliers.showcaseProducts"),
              t("fabric-suppliers.easilyManage"),
              t("fabric-suppliers.digitalMaterials"),
              t("fabric-suppliers.benefitServices"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide3, Slide4],
        },
        isTextFirst: false,
      },
      {
        info: {
          title: {
            text: t("fabric-suppliers.halfMilion"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("fabric-suppliers.getNotified"),
              t("fabric-suppliers.gainInsights"),
              t("fabric-suppliers.noAgency"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide5, Slide6],
        },
        isTextFirst: true,
      },
      {
        info: {
          title: {
            text: t("fabric-suppliers.gainMarket"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("fabric-suppliers.accessData"),
              t("fabric-suppliers.exploreClients"),
              t("fabric-suppliers.contactProspects"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide7, Slide8],
        },
        isTextFirst: false,
      },
      {
        info: {
          title: {
            text: t("fabric-suppliers.enhanceMarketing"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("fabric-suppliers.convertProfile"),
              t("fabric-suppliers.rankSEO"),
              t("fabric-suppliers.manageVisibility"),
              t("fabric-suppliers.shareLink"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide9],
        },
        isTextFirst: true,
        cta: {
          text: t("fabric-suppliers.joinFree"),
          url:
           process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
    ],
  };
};
